import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  nativeGlobal() { return window; }
}
