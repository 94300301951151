import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { RolesGuard } from './core/auth/guards/roles.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/orders'
    {path: '', pathMatch : 'full', redirectTo: 'welcome'},

    // Redirect signed in user to the '/orders'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'welcome'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Admin routes
    {
        path       : '',
        canActivate: [AuthGuard, RolesGuard],
        canActivateChild: [AuthGuard, RolesGuard],
        component  : LayoutComponent,
        children   : [
            {path: 'welcome', loadChildren: () => import('app/modules/admin/welcome/welcome.module').then(m => m.WelcomeModule), data: {roles: ['admin']}},
            {path: 'participants', loadChildren: () => import('app/modules/admin/participants/participant.module').then(m => m.ParticipantModule), data: {roles: ['admin']}},
            {path: 'sessions', loadChildren: () => import('app/modules/admin/sessions/session.module').then(m => m.SessionsModule), data: {roles: ['admin']}},
            {path: 'answer-sheets', loadChildren: () => import('app/modules/admin/answer-sheets/answer-sheets.module').then(m => m.AnswerSheetsModule), data: {roles: ['admin']}},
            {path: 'contestations', loadChildren: () => import('app/modules/admin/contestations/contestations.module').then(m => m.ContestationsModule), data: {roles: ['admin']}},
            {path: 'sessions-feedback', loadChildren: () => import('app/modules/admin/sessions-feedback/sessions-feedback.module').then(m => m.SessionsFeedbackModule), data: {roles: ['admin']}},
            {path: 'tests', loadChildren: () => import('app/modules/admin/sessions-tests/session-test.module').then(m => m.SessionsTestsModule), data: {roles: ['admin']}},
            {path: 'sessions-etapa1-2024', loadChildren: () => import('app/modules/admin/etapa1-2024/sessions/session.module').then(m => m.SessionsModule), data: {roles: ['admin']}},
            {path: 'sessions-etapa1-2023', loadChildren: () => import('app/modules/admin/etapa1-2023/sessions/session.module').then(m => m.SessionsModule), data: {roles: ['admin']}},
            {path: 'sessions-etapa2-2023', loadChildren: () => import('app/modules/admin/etapa2-2023/sessions/session.module').then(m => m.SessionsModule), data: {roles: ['admin']}},
            {path: 'sessions-feedback-etapa1-2024', loadChildren: () => import('app/modules/admin/etapa1-2024/sessions-feedback/sessions-feedback.module').then(m => m.SessionsFeedbackModule), data: {roles: ['admin']}},
            {path: 'sessions-feedback-etapa1-2023', loadChildren: () => import('app/modules/admin/etapa1-2023/sessions-feedback/sessions-feedback.module').then(m => m.SessionsFeedbackModule), data: {roles: ['admin']}},
            {path: 'sessions-feedback-etapa2-2023', loadChildren: () => import('app/modules/admin/etapa2-2023/sessions-feedback/sessions-feedback.module').then(m => m.SessionsFeedbackModule), data: {roles: ['admin']}},
            {path: 'tests-etapa1-2024', loadChildren: () => import('app/modules/admin/etapa1-2024/sessions-tests/session-test.module').then(m => m.SessionsTestsModule), data: {roles: ['admin']}},
            {path: 'tests-etapa1-2023', loadChildren: () => import('app/modules/admin/etapa1-2023/sessions-tests/session-test.module').then(m => m.SessionsTestsModule), data: {roles: ['admin']}},
            {path: 'tests-etapa2-2023', loadChildren: () => import('app/modules/admin/etapa2-2023/sessions-tests/session-test.module').then(m => m.SessionsTestsModule), data: {roles: ['admin']}},
        ]
    },

    {path: '**', pathMatch : 'full', redirectTo: 'sign-in'},

];
