import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Md5 } from 'ts-md5';
import { User } from '../user/user.types';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
     get accessToken(): string
     {
         return localStorage.getItem('accessToken') ?? '';
     }

    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        credentials.password = Md5.hashStr(credentials.password);

        return this._httpClient.post(`${environment.httpBasePath}/admin/user/authentication`, credentials).pipe(
            switchMap((response: any) => {

                // Set user authentication data
                this.setAuthUserData(response);

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post(`${environment.httpBasePath}/admin/user/refresh-token`, {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Set user authentication data
                this.setAuthUserData(response);

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Check the authentication status
     */
    checkRoles(roles: string[]): Observable<boolean>
    {
        let role: string = '';

        // Get the user's role
        this._userService.user$.subscribe((user) => {
            role = user.role;
        }).unsubscribe();

        if (!!!roles.includes(role)) {
            return of(false);
        }

        return of(true);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    private setAuthUserData(response: any): void {
        // Store the access token in the local storage
        this.accessToken = response.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        let user: User = {
            role: 'admin',
            email: 'admin@admin.com',
            avatar: 'assets/images/avatars/default.jpg'
        };

        const decodedToken = AuthUtils.decodeToken(this.accessToken);
        if (decodedToken &&
            decodedToken.email &&
            decodedToken.role) {
            user = {
                role: decodedToken.role,
                email: decodedToken.email,
                avatar: 'assets/images/avatars/default.jpg'
            };
        }

        // Store the user on the user service
        this._userService.user = user;
    }
}
