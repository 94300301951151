/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const adminNavigation: FuseNavigationItem[] = [
    {
        id   : 'participants',
        title: 'Utilizatori',
        type : 'basic',
        icon : 'heroicons_outline:user',
        link : '/participants'
    },
    {
        id   : 'sessions',
        title: 'Sesiuni',
        type : 'basic',
        icon : 'heroicons_outline:calculator',
        link : '/sessions'
    },
    {
        id   : 'sessions-feedback',
        title: 'Feedback',
        type : 'basic',
        icon : 'heroicons_outline:color-swatch',
        link : '/sessions-feedback'
    },
    {
        id   : 'tests',
        title: 'Teste',
        type : 'basic',
        icon : 'heroicons_outline:newspaper',
        link : '/tests'
    },
    {
        id   : 'answer-sheets',
        title: 'Borderouri',
        type : 'basic',
        icon : 'heroicons_outline:table',
        link : '/answer-sheets'
    },
    {
        id   : 'contestations',
        title: 'Contestatii',
        type : 'basic',
        icon : 'heroicons_outline:flag',
        link : '/contestations'
    },
    {
        type : 'divider'
    },
    {
        id   : 'etapa1-2024',
        title: 'Etapa I 2024',
        type : 'collapsable',
        icon : 'heroicons_outline:academic-cap',
        children: [
            {
                id   : 'sessions-etapa1-2024',
                title: 'Sesiuni',
                type : 'basic',
                icon : 'heroicons_outline:calculator',
                link : '/sessions-etapa1-2024'
            },
            {
                id   : 'sessions-feedback-etapa1-2024',
                title: 'Feedback',
                type : 'basic',
                icon : 'heroicons_outline:color-swatch',
                link : '/sessions-feedback-etapa1-2024'
            },
            {
                id   : 'tests-etapa1-2024',
                title: 'Teste',
                type : 'basic',
                icon : 'heroicons_outline:newspaper',
                link : '/tests-etapa1-2024'
            },
        ]
    },
    {
        id   : 'etapa2-2023',
        title: 'Etapa 2 2023',
        type : 'collapsable',
        icon : 'heroicons_outline:academic-cap',
        children: [
            {
                id   : 'sessions-etapa2-2023',
                title: 'Sesiuni',
                type : 'basic',
                icon : 'heroicons_outline:calculator',
                link : '/sessions-etapa2-2023'
            },
            {
                id   : 'sessions-feedback-etapa2-2023',
                title: 'Feedback',
                type : 'basic',
                icon : 'heroicons_outline:color-swatch',
                link : '/sessions-feedback-etapa2-2023'
            },
            {
                id   : 'tests-etapa2-2023',
                title: 'Teste',
                type : 'basic',
                icon : 'heroicons_outline:newspaper',
                link : '/tests-etapa2-2023'
            },
        ]
    },
    {
        id   : 'etapa1-2023',
        title: 'Etapa I 2023',
        type : 'collapsable',
        icon : 'heroicons_outline:academic-cap',
        children: [
            {
                id   : 'sessions-etapa1-2023',
                title: 'Sesiuni',
                type : 'basic',
                icon : 'heroicons_outline:calculator',
                link : '/sessions-etapa1-2023'
            },
            {
                id   : 'sessions-feedback-etapa1-2023',
                title: 'Feedback',
                type : 'basic',
                icon : 'heroicons_outline:color-swatch',
                link : '/sessions-feedback-etapa1-2023'
            },
            {
                id   : 'tests-etapa1-2023',
                title: 'Teste',
                type : 'basic',
                icon : 'heroicons_outline:newspaper',
                link : '/tests-etapa1-2023'
            },
        ]
    }
];


export const orderOperatorNavigation: FuseNavigationItem[] = [
    {
        id   : 'participants',
        title: 'Utilizatori',
        type : 'basic',
        icon : 'heroicons_outline:user',
        link : '/participants'
    },
    {
        id   : 'sessions',
        title: 'Sesiuni',
        type : 'basic',
        icon : 'heroicons_outline:calculator',
        link : '/sessions'
    },
    {
        id   : 'tests',
        title: 'Teste',
        type : 'basic',
        icon : 'heroicons_outline:newspaper',
        link : '/tests'
    },
];
