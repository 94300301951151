import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, ReplaySubject, tap } from 'rxjs';
import { Subject, takeUntil } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { adminNavigation, orderOperatorNavigation } from './navigation.data';
import { cloneDeep } from 'lodash';
import { UserService } from '../user/user.service';
import { User } from '../user/user.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationService implements OnDestroy
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private _user: User;

    /**
     *
     */
    constructor(private _userService: UserService) {
        // Subscribe to navigation data
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this._user = user;
            });

        let navigation = {
            default   : cloneDeep(orderOperatorNavigation)
        };

        if (this._user.role === 'admin'){
            navigation = {
                default   : cloneDeep(adminNavigation)
            };
        }

        this._navigation.next(navigation);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }


    /**
     * Getter for navigation
     */
     get(): Observable<Navigation>
     {
         return this._navigation.asObservable();
     }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
