import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'mathjax',
  templateUrl: './mathjax.component.html'
})
export class MathjaxComponent implements OnChanges, OnDestroy {
  @Input() content!: string;

  constructor(public cs: ConfigService) {
    this.loadMathConfig();
    this.renderMath();
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  mathJaxObject: any;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnChanges(changes: SimpleChanges) {
    // to render math equations again on content change
    if (changes['content']) {
      this.renderMath();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnDestroy() {
    this.mathJaxObject = null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  updateMathObt(){
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.mathJaxObject = this.cs?.nativeGlobal()['MathJax']!;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  renderMath() {
    this.updateMathObt();
    if (this.mathJaxObject && this.mathJaxObject['Hub']){
      this.mathJaxObject['Hub'].Queue(['Typeset', this.mathJaxObject.Hub]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadMathConfig() {
    this.updateMathObt();
    this.mathJaxObject.Hub.Config({
      'showMathMenu': false,
      'tex2jax': { inlineMath: [['$', '$']], displayMath:[['$$', '$$']] },
      'menuSettings': { zoom: 'Double-Click', zscale: '150%' },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'CommonHTML': { linebreaks: { automatic: false } },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'HTML-CSS': { linebreaks: { automatic: false } },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'SVG': { linebreaks: { automatic: true } }
    });
  }
}
